import React, { Component } from 'react'
import InputField from '../../components/inputField'
import './recoveryPassword.css'
import ApiCall from '../../services/api'
import Modal from '../../components/modal'
import './normalize.css';
import { Redirect } from 'react-router-dom'

class RecoveryPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            recovery: '',
            modalOpen: false,
            content: '',
            phone: '',
            email: '',
            id: '',
            redirectToReferrer: false,
            error: false,
            button_value: props.match.params.button_value,
        }
    }

    setPhoneType = (number) => {
        if(number){
            const count = Object.keys(number.trim()).length;
            if(count === 15){
                this.setState({
                   phone: '(' + number.substring(2,4) + ') ' + number.substring(5,10) + '-' + number.substring(11)
                })
                // this.state.phone = '(' + number.substring(2,4) + ') ' + number.substring(5,10) + '-' + number.substring(11)
            }else if(count === 14){
                this.setState({
                   phone: '(' + number.substring(2,4) + ') ' + number.substring(5,9) + '-' + number.substring(10)
                })
                // this.state.phone = '(' + number.substring(2,4) + ') ' + number.substring(5,9) + '-' + number.substring(10)
            }
        }
    }

    handleChangeInputValue = (event) => {
        this.setState({modalOpen: false});
        const {
            target: {
                name, type, placeholder, value
            }
        } = event;

        this.setState({
            [name] : value,
            [type] : value,
            [placeholder] : value
        })
    }

    handleRecovery = () => {
        ApiCall('/membros/code?login='+this.state.recovery, 'GET', {})
            .then(res => {
                if (res.status_code !== 200){
                    this.setState({error: true})
                }else {
                    this.setState({error: false})
                    this.setPhoneType(res.data.celular);
                    this.setState({id: res.data.seq_cadast})
                    // this.state.id = res.data.seq_cadast;
                    let celular = <p className="button">Nenhum Celular Encontrado</p>;
                    if(res.data.celular){
                        celular =
                            <button onClick={() => this.handleSendToken('sms', this.state.id)}
                                    className="button  button--round-s button--border-thin">
                                {/* <i className="button__icon icon icon-microphone"></i> */}
                                <span>Enviar SMS</span>
                            </button>
                    }
                    let email = <p className="button">Nenhum Email Encontrado</p>;
                    if(res.data.email){
                        email =
                            <button onClick={() => this.handleSendToken('email', this.state.id)}
                                    className="button  button--round-s button--border-thin">
                                {/* <i className="button__icon icon icon-envelope"></i> */}
                                <span>Enviar E-mail</span>
                            </button>
                    }
                    let messageError = ''
                    if(!res.data.email && !res.data.celular){
                        messageError = <div className="alert alert-danger">
                            <span>Não conseguimos encontrar seus dados, por favor entre em contato pelo telefone (41)3351-3000.</span>
                        </div>
                    }
                    this.setState({
                        modalOpen: true, content:
                            <div>
                                {messageError}
                                <div className="boxes">
                                    <div className="input-box">
                                        <div className="button-box">
                                            <p>Utilizar o celular</p>
                                            <p>{this.state.phone}</p>
                                            {celular}
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <div className="button-box">
                                            <p>Utilizar o email</p>
                                            <p>{res.data.email}</p>
                                            {email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    })
                }
            })
    }

    handleSendToken = (type, id) => {
        const params = {
            'type' : type,
            'seq_cadast' : id
        }
       ApiCall('/auth/code', 'POST', params)
            .then(res => {
                if(res.code === 200) {
                    this.setState({redirectToReferrer: true});
                }
            })
          
            
    }

    render(){
        const { redirectToReferrer } = this.state;
        const { error } = this.state;
        let errorAlert = '';

        if (redirectToReferrer) {
            return <Redirect to={'/reset/' + this.state.id} />;
        }

        if(error){
            errorAlert = <div className="alert alert-danger">
                <span>Não encontramos seu usário, verifique os dados.</span>
            </div>
        }

        let texto = '';
        if(this.state.button_value === 'Cadastrar') {
            texto = 'cadastrar sua conta'
        }else {
            texto = 'recuperar sua senha'
        }

        return (
            <div>   
                <Modal
                    title="Para continuar com esta ação, por favor escolha um dos dois métodos abaixo para envio do TOKEN"
                    content={this.state.content}
                    opened={this.state.modalOpen}
                />
                <p className="content-title">Informe seu dado para {texto}</p>
                {errorAlert}
                <InputField
                    value={this.state.recovery}
                    name="recovery"
                    type="text"
                    placeholder="Email / CPF / cod OGG"
                    onChange={this.handleChangeInputValue}
                />
                <InputField
                    value={this.state.button_value}
                    type="button"
                    className="button-primary"
                    onClick={this.handleRecovery}
                />

            </div>
        )
    }

}
export default RecoveryPassword
