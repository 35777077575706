import React, { Component } from 'react'
import './Layout.css'
import logoRc from '../../assets/logo-amorc.png'

class Layout extends Component {

    handleChangeInputValue = (event) => {
        const {
            target: {
                name, type, placeholder, value
            }
        } = event;

        this.setState({
            [name] : value,
            [type] : value,
            [placeholder] : value
        })
    }

    render() {
        return (
            <div className="Layout">
                <div className="content box">
                    <div className="panel box">
                        <div className="panel head-box">
                            <img src={logoRc} alt='AMORC' />
                            <h1 className="panel head-title"><strong>Amorc - GLP</strong></h1>
                            <hr className="panel head-content-divisor"/>
                        </div>
                        <div className="panel children">
                            { this.props.children }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Layout
