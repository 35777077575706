import React, { Component } from 'react';
import InputField from '../../components/inputField';
import './Login.css';
import ApiCall from '../../services/api';

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            login: '',
            password: '',
            grant_type: 'password',
            client_id: props.match.params.client_id,
            error: false
        }
    }
    componentWillMount() {
        window.localStorage.setItem('client_id', this.props.match.params.client_id)
    }

    handleChangeInputValue = (event) => {
        const {
            target: {
                name, type, placeholder, value
            }
        } = event;

        this.setState({
            [name]: value,
            [type]: value,
            [placeholder]: value
        })
    }

    handleLoginUser = (e) => {
        e.preventDefault()
        const params = {
            'login': this.state.login,
            'password': this.state.password,
            'grant_type': this.state.grant_type,
            'client_id': this.state.client_id
        };

        ApiCall('/login', 'POST', params)
            .then(result => {
                if (result.status_code !== 200) {
                    this.setState({ error: true })
                } else {
                    this.setState({ error: false })
                    window.opener.postMessage(result, '*')
                }
            })
    }

    render() {
        let errorAlert = '';

        if (this.state.error) {
            errorAlert = <div className="alert alert-danger">
                <span>Os dados informados não são válidos.</span>
            </div>
        }
        return (
            <div>
                <p className="content-title">Informe seus dados para entrar</p>
                {errorAlert}
                <form onSubmit={this.handleLoginUser}>
                    <InputField
                        value={this.state.login}
                        name="login"
                        type="text"
                        placeholder="Email / CPF / cod OGG"
                        onChange={this.handleChangeInputValue}
                    />
                    <InputField
                        value={this.state.password}
                        name="password"
                        type="password"
                        placeholder="Senha"
                        onChange={this.handleChangeInputValue}
                    />
                    <InputField
                        value="Entrar"
                        type="submit"
                        className="button-primary"
                    />
                </form>

                <a onClick={() => window.localStorage.setItem('client_id', this.props.match.params.client_id)} href="/recovery/Cadastrar">
                    <small>Criar uma conta</small>
                </a>
                <a onClick={() => window.localStorage.setItem('client_id', this.props.match.params.client_id)} href="/recovery/Recuperar">
                    <small>Esqueceu a senha?</small>
                </a>
            </div>
        )
    }
}
export default Login;
