import React, { Component } from 'react'
import './modal.css'

class Modal extends Component {


    constructor(props){
        super(props)

        this.state = {
            opened : props.opened ? 'opened' : '',
            content : props.content ? props.content : 0,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(
            {opened : nextProps.opened ? 'opened' : ''}
        )
    }

    handleClose() {
        this.setState(
            {opened : ''}
        )
    }

    render() {
        return (
            <div className={"modal " + this.state.opened}>
                <div className="content">
                    <div className="icon-close" onClick={this.handleClose.bind(this)}></div>
                    <div className="modal-title">
                        <h1>{ this.props.title }</h1>
                    </div>
                    <div className="modal-content">
                        { this.props.content}
                    </div>
                </div>
            </div>
        )
    }
}
export default Modal