import axios from 'axios'
const API_URI = 'https://api.amorc.org.br/v1';
// const API_URI = 'http://127.0.0.1:8000/v1';

const ApiCall = (url, method, params) => {

    if(method === 'GET'){
        url = url + Object.keys(params)
                .reduce((prevState, current) =>
                    `${prevState}${current}=${params[current]}&`, '?')
        params = {};
    }

    return axios({
        url : url,
        method : method,
        baseURL : API_URI,
        data: params,
    }).then(function(response) {
        return response.data
    }).catch(function (error) {
        return error.response.data
    });
}

export default ApiCall
