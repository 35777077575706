import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './containers/Login';
import RecoveryPassword from './containers/RecoveryPassword'
import Layout from "./containers/Layout";
import ResetPassword from './containers/ResetPassword'

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Layout>
                    <Route path="/login/:client_id" component={Login} exact={true}/>
                    <Route path="/recovery/:button_value" component={RecoveryPassword} exact={true}/>
                    <Route path="/reset/:id" component={ResetPassword} exact={true}/>
                </Layout>
            </BrowserRouter>
        );
    }
}

export default App;