import React from 'react'
import './inputField.css'
import InputMask from 'react-input-mask'

const InputField = (props) => {
    return (
        <div className="form-group">
            <label className="hiddenWAI" htmlFor={props.name}></label>
            <InputMask {...props}></InputMask>
        </div>
    )
}
export default InputField
